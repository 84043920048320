.message-container{
    height: 72vh !important;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.chat-container {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    right: 1px;
    padding: 10px;
}
.chat-input input {
    width: calc(100% - 490px) !important;
    padding: 20px;
    font-size: 16px;
}

.send-btn {
    padding: 10px;
    background-color: #007bff;
    border: none;
    display: inline;
    border-radius: 0 5px 5px 0;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}
@keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .loading-image {
    animation: fadeInOut 2s infinite ease-in-out;
  }
  